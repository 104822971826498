import { createApp, Directive } from 'vue'
import App from './App.vue'
import router from './router'
import piniaPersist from 'pinia-plugin-persist';
import "@/assets/reset.scss"
import "@/assets/public.scss"
const app = createApp(App)
// 循环注册指令
import * as directives from './directives';
Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
const pinia = createPinia();
pinia.use(piniaPersist);
document.documentElement.style.setProperty('--allfontColor', '#3b7960');
document.documentElement.style.setProperty('--allbgColor', '#ffffff');
document.documentElement.style.setProperty('--allbgColor2', '#F7F8FC');
document.documentElement.style.setProperty('--modelBg', '#ffffff');
document.documentElement.style.setProperty('--modelColor', 'rgba(0, 0, 0, 0.5)');
app.use(pinia).use(ElementPlus).use(router).mount('#app')
