import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import layout from "@/components/layout/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/login/index.vue"),
  },
  {
    path: "/",
    name: "layout",
    component: layout,
    redirect: "/login",
    children: [
      {
        path: "/user",
        name: "user",
        meta: {
          title: "用户管理",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/user/index.vue"),
      },
      {
        path: "/role",
        name: "role",
        meta: {
          title: "角色管理",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/role/index.vue"),
      },
      {
        path: "/handleLog",
        name: "handleLog",
        meta: {
          title: "操作日志",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/handleLog/index.vue"),
      },
      {
        path: "/personnelManagement",
        name: "personnelManagement",
        meta: {
          title: "人员管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/personnelManagement/index.vue"
          ),
      },
      {
        path: "/banner",
        name: "banner",
        meta: {
          title: "轮播图管理",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/banner/index.vue"),
      },
      {
        path: "/homeManage",
        name: "homeManage",
        meta: {
          title: "首页管理",
        },
        redirect: "/teachingAdvantages",
        // component: () => import(/* webpackChunkName: "about" */ '@/views/homeManage/index.vue'),
        children: [
          {
            path: "/teachingAdvantages",
            name: "teachingAdvantages",
            meta: {
              title: "教学优势",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/homeManage/teachingAdvantages.vue"
              ),
          },
          {
            path: "/collegePlanningProcess",
            name: "collegePlanningProcess",
            meta: {
              title: "升学案例",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/homeManage/collegePlanningProcess.vue"
              ),
          },
          {
            path: "/newSeedlingAcademy",
            name: "newSeedlingAcademy",
            meta: {
              title: "学校简介",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/homeManage/newSeedlingAcademy.vue"
              ),
          },
        ],
      },
      {
        path: "/educationManage",
        name: "educationManage",
        meta: {
          title: "教学管理",
        },
        redirect: "/hkdseCourseSystem",
        children: [
          {
            path: "/hkdseCourseSystem",
            name: "hkdseCourseSystem",
            meta: {
              title: "HKDSE课程体系",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/educationManage/hkdseCourseSystem.vue"
              ),
          },
          // {
          //   path: "/dseFitStudent",
          //   name: "dseFitStudent",
          //   meta: {
          //     title: "DSE课程适合学生",
          //   },
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "about" */ "@/views/educationManage/dseFitStudent.vue"
          //     ),
          // },
          // {
          //   path: "/dseAdvantage",
          //   name: "dseAdvantage",
          //   meta: {
          //     title: "DSE课程优势及特点",
          //   },
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "about" */ "@/views/educationManage/dseAdvantage.vue"
          //     ),
          // },
          {
            path: "/dseTeachingCharacteristics",
            name: "dseTeachingCharacteristics",
            meta: {
              title: "新苗DSE教学特色",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/educationManage/dseTeachingCharacteristics.vue"
              ),
          },
          {
            path: "/educationalManagement",
            name: "educationalManagement",
            meta: {
              title: "教学管理",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/educationManage/educationalManagement.vue"
              ),
          },
          {
            path: "/courseSchedule",
            name: "courseSchedule",
            meta: {
              title: "课程安排",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/educationManage/courseSchedule.vue"
              ),
          },
          {
            path: "/dayToDayManagement",
            name: "dayToDayManagement",
            meta: {
              title: "日常管理",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/educationManage/dayToDayManagement.vue"
              ),
          },
        ],
      },
      {
        path: "/upSchoolManage",
        name: "upSchoolManage",
        meta: {
          title: "升学管理",
        },
        redirect: "/advantagesOfTheCareersTeam",
        children: [
          {
            path: "/advantagesOfTheCareersTeam",
            name: "advantagesOfTheCareersTeam",
            meta: {
              title: "升学团队优势",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/upSchoolManage/advantagesOfTheCareersTeam.vue"
              ),
          },
          {
            path: "/collegePlanningProcess2",
            name: "collegePlanningProcess2",
            meta: {
              title: "升学规划流程",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/upSchoolManage/collegePlanningProcess2.vue"
              ),
          },
          {
            path: "/keyTocollegeApplication",
            name: "keyTocollegeApplication",
            meta: {
              title: "院校申请的关键",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/upSchoolManage/keyTocollegeApplication.vue"
              ),
          },
          {
            path: "/GuidanceForFurtherStudies",
            name: "GuidanceForFurtherStudies",
            meta: {
              title: "升学指导",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/upSchoolManage/GuidanceForFurtherStudies.vue"
              ),
          },
          {
            path: "/caseStudies",
            name: "caseStudies",
            meta: {
              title: "升学案例",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/upSchoolManage/caseStudies.vue"
              ),
          },
        ],
      },
      {
        path: "/concernManage",
        name: "concernManage",
        meta: {
          title: "关于新苗管理",
        },
        redirect: "/introductionOfNewSeedlings",
        children: [
          {
            path: "/introductionOfNewSeedlings",
            name: "introductionOfNewSeedlings",
            meta: {
              title: "新苗介绍",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/concernManage/introductionOfNewSeedlings.vue"
              ),
          },
          {
            path: "/teacherConfig",
            name: "teacherConfig",
            meta: {
              title: "任课教师团",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/concernManage/teacherConfig.vue"
              ),
          },
          {
            path: "/tutorConfig",
            name: "tutorConfig",
            meta: {
              title: "升学导师团",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/concernManage/tutorConfig.vue"
              ),
          },
          {
            path: "/schoolEnvironment",
            name: "schoolEnvironment",
            meta: {
              title: "教学环境",
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/views/concernManage/schoolEnvironment.vue"
              ),
          },
        ],
      },
      {
        path: "/permanentStructure",
        name: "permanentStructure",
        meta: {
          title: "理事机构",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/permanentStructure/permanentStructureInfo.vue"
          ),
      },
      {
        path: "/branchSchoolInformation",
        name: "branchSchoolInformation",
        meta: {
          title: "分校信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/branchSchoolInformation/branchSchools.vue"
          ),
      },
      {
        path: "/campusLife",
        name: "campusLife",
        meta: {
          title: "校园生活",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/campusLife/index.vue"
          ),
      },
      {
        path: "/news",
        name: "news",
        meta: {
          title: "新闻管理",
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/news/schoolNews.vue"),
      },
      {
        path: "/recruitStudentPlan",
        name: "recruitStudentPlan",
        meta: {
          title: "招生计划",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/homeManage/recruitStudentPlan.vue"
          ),
      },
      {
        path: "/concat",
        name: "concat",
        meta: {
          title: "联系我们"
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/concat/index.vue"
          ),
      },
      {
        path: "/userCallbackInfo",
        name: "userCallbackInfo",
        meta: {
          title: "弹窗咨询"
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/userCallbackInfo/index.vue"
          )},
      // {   
      //   path: "/enrollmentPlan",
      //   name: "enrollmentPlan",
      //   meta: {
      //     title: "招生计划",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "about" */ "@/views/enrollmentPlan/index.vue"
      //     ),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
