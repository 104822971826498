import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41887908"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout"
};
const _hoisted_2 = {
  class: "li-header"
};
const _hoisted_3 = {
  class: "li-content w-bf100"
};
const _hoisted_4 = {
  class: "content"
};
export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header");
  const _component_router_view = _resolveComponent("router-view");
  const _directive_scroll = _resolveDirective("scroll");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Header)]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_router_view)])])), [[_directive_scroll]])]);
}