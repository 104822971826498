import { Directive } from 'vue';
export const scroll: Directive = {
  mounted(el, binding) {
    let loadScroll = true;
    document.addEventListener('mousewheel', (e: any) => {
      const scrollTop = e.wheelDelta;
      // const windowHeight = e.target.clientHeight;
      // const scrollHeight = e.target.scrollHeight;
      if (scrollTop > 0) {
        // 当前滚动条已经触底
        loadScroll = false;
        setTimeout(() => {
          loadScroll = true;
        }, 1000);
      }
    });
  },
};
